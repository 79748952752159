import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Skills from "../components/Skills";
import { about_para1, about_para2 } from "../data/about";
import profilePhoto from "../assets/images/profile.png";
import Experience from "./Experience";

const About = () => {
  const pageTheme = useTheme();
  const isMobile = useMediaQuery(pageTheme.breakpoints.down("md"));
  return (
    <>
      <Grid id="About" container p={2}>
        <Grid item xs={12}>
          <Typography variant="h3">About Me</Typography>
        </Grid>

        <Grid item xs={12} md={12} sx={{ textAlign: "center", height: "100%" }}>
          <img
            src={profilePhoto}
            alt="profilePhoto"
            style={{
              maxWidth: isMobile ? "80%" : "40%",
              maxHeight: isMobile ? "80%" : "40%",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            lineHeight={"normal"}
            dangerouslySetInnerHTML={{ __html: about_para1 }}
          />

          <Typography
            lineHeight={"normal"}
            mt={2}
            dangerouslySetInnerHTML={{ __html: about_para2 }}
          />
        </Grid>
      </Grid>
      <Experience />
      <Skills />
    </>
  );
};

export default About;
