import { Button, Grid, Typography } from "@mui/material";
import "react-vertical-timeline-component/style.min.css";
import { VerticalTimeline } from "react-vertical-timeline-component";
import ExperienceCard from "../components/ExperienceCard";
import { experience, resumeLink } from "../data/experience";
import LaunchIcon from "@mui/icons-material/Launch";
import { primaryColor } from "../data/color";

const Experience = () => {
  return (
    <Grid id="Experience" container p={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Experience</Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{
            backgroundColor: "#fff",
            color: "black",
            "&:hover": {
              backgroundColor: primaryColor,
              color: "white",
            },
            border: "1px solid #edebe3",
            fontWeight: 1000,
          }}
          size="small"
          href={resumeLink}
          target="_blank"
          rel="noopener"
          // variant="contained"
          endIcon={<LaunchIcon />}
        >
          Resume
        </Button>
        <VerticalTimeline lineColor="#edebe3" layout="1-column">
          {experience.map(
            (
              { date, company, content, designation, technologies, logo },
              index
            ) => (
              <ExperienceCard
                date={date}
                designation={designation}
                company={company}
                content={content}
                technologies={technologies}
                logo={logo}
                key={index}
              />
            )
          )}
        </VerticalTimeline>
        <Button
          sx={{
            backgroundColor: "#fff",
            color: "black",
            "&:hover": {
              backgroundColor: primaryColor,
              color: "white",
            },
            border: "1px solid #edebe3",
            fontWeight: 1000,
          }}
          size="small"
          href={
            "https://drive.google.com/file/d/1_vrlEzJVbalUrl9ZLFofoyrpGKOOaFno/view?usp=sharing"
          }
          target="_blank"
          rel="noopener"
          // variant="contained"
          endIcon={<LaunchIcon />}
        >
          Resume
        </Button>
      </Grid>
    </Grid>
  );
};

export default Experience;
