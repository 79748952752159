import ResponsiveGallery from "react-responsive-gallery";
import { mediaData } from "../data/gallery";

const Gallery = ({ media }: any) => {
  return (
    <div className="gallery-container">
      <ResponsiveGallery
        useLightBox
        numOfMediaPerRow={{
          xs: 1,
          s: 1,
          m: 1,
          l: 2,
          xl: 2,
          xxl: 3,
        }}
        media={mediaData}
      />
    </div>
  );
};

export default Gallery;
