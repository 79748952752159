import { useEffect } from "react";

const Version = ({ version = "1.0.0" }) => {
  useEffect(() => {
    console.log(`
        *******  *****  *     *  *****
        *     *  *   *  *     *    *  
        *******  *****  *******    *  
        *     *  *   *  *     *    *  
        *     *  *****  *     *  *****
        `);
    console.log(`App Version: ${version}`);
  }, [version]);

  return <></>;
};

export default Version;
