import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { primaryColor } from "../data/color";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  window?: () => Window;
}

const navItems = ["About", "Experience", "Projects", "Contact"];

export default function Navbar(props: Props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMenuItemClick = (item: string) => {
    setMobileOpen(false);
    const target = document.getElementById(item);
    if (target) {
      target.scrollIntoView({ behavior: "smooth", inline: "start" });
    }
  };

  const pageTheme = useTheme();
  const isMobile = useMediaQuery(pageTheme.breakpoints.down("md"));

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }} mb={8}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          width: isMobile ? "96%" : "50%",
          borderRadius: "15px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          backgroundColor: `rgba(226,223,210, 0.6)`,
          color: primaryColor,
          // marginTop: "5px", // Center the navbar horizontally
          right: "auto",
          backdropFilter: "blur(10px)", // Blur effect
          WebkitBackdropFilter: "blur(10px)", // For Safari
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              justifyContent: "space-evenly", // evenly distribute items along the main axis
              fontFamily: "fantasy",
            }}
          >
            {navItems.map((item) => (
              <Button
                onClick={() => handleMenuItemClick(item)}
                key={item}
                sx={{
                  color: primaryColor,
                  fontWeight: 800,
                  fontSize: "18px",
                }}
              >
                {item}
              </Button>
            ))}
          </Box>
          {/* TODO: Dark mode */}
          {/* <Box>
            <Switch />
          </Box> */}
        </Toolbar>
      </AppBar>
      <Dialog
        fullScreen
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: `rgba(226,223,210, 0.8)`, // Semi-transparent background
            backdropFilter: "blur(10px)", // Blur effect
            WebkitBackdropFilter: "blur(10px)", // For Safari
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <IconButton
          color="inherit"
          onClick={handleDrawerToggle}
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <List sx={{ textAlign: "center" }}>
          {navItems.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton
                onClick={() => handleMenuItemClick(item)}
                sx={{ textAlign: "center" }}
              >
                <Typography fontSize={"42px"} fontWeight={800}>
                  {item}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </Box>
  );
}
