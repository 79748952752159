import "./experience.css";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { Box, Chip, Typography } from "@mui/material";
import { primaryColor } from "../data/color";

const ExperienceCard = ({
  date,
  designation,
  company,
  content,
  technologies,
  logo,
}: {
  date: string;
  designation: string;
  company: string;
  content: string;
  technologies: string[];
  logo: string;
}) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: "#edebe3", color: "#000" }}
      contentArrowStyle={{ borderRight: "7px solid  #edebe3" }}
      date={date}
      iconStyle={{
        background: "rgb(226, 223, 210)",
        color: "#fff",
        // border: "2px solid black",
      }}
      dateClassName={"customDate"}
      icon={<img height={"100%"} width={"100%"} src={logo} alt="logo" />}
    >
      <h3 className="vertical-timeline-element-title">{designation}</h3>

      <h4 className="vertical-timeline-element-subtitle">{company}</h4>
      <Typography component="div">
        <Box display="flex" flexWrap="wrap" gap={0.5} mt={1}>
          {technologies.map((tech, index) => (
            <Chip
              key={index}
              size="small"
              label={tech}
              variant="outlined"
              color="primary"
              sx={{
                "&:hover": {
                  backgroundColor: primaryColor,
                  color: primaryColor,
                },
                fontWeight: "bold",
                color: primaryColor,
                borderColor: primaryColor,
                borderRadius: "5px",
              }}
            />
          ))}
        </Box>
      </Typography>
      <p
        dangerouslySetInnerHTML={{ __html: content }}
        style={{ lineHeight: "normal" }}
      ></p>
    </VerticalTimelineElement>
  );
};

export default ExperienceCard;
