import { Button, Grid, Typography } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import { primaryColor } from "../data/color";

const Contact = () => {
  const timeZone = "Asia/Kolkata";
  const currentHour = new Date().getHours();
  const currentTime = new Date().toLocaleString("en-US", {
    timeZone,
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <Grid id="Contact" container spacing={2} pl={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Contact</Typography>
      </Grid>
      <Grid item xs={12}>
        {(Number(currentHour) >= 23 || Number(currentHour) < 7) && (
          <Typography>
            My current local time is <b>{currentTime}</b>, I might be catching
            some sleep. However, I'll respond to you promptly as soon as I'm
            available!
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography>Reach out to me on</Typography>
        <Button
          LinkComponent={"a"}
          startIcon={<EmailIcon />}
          href="mailto: abhijeetsatpute98@gmail.com"
          sx={{
            color: primaryColor,
          }}
        >
          Email
        </Button>
        <Button
          LinkComponent={"a"}
          startIcon={<LinkedInIcon />}
          href="https://www.linkedin.com/in/abhijeetsatpute/"
          target="_blank"
          sx={{
            color: primaryColor,
          }}
        >
          LinkedIn
        </Button>

        <Button
          LinkComponent={"a"}
          startIcon={<GitHubIcon />}
          href="https://github.com/abhijeetsatpute"
          target="_blank"
          sx={{
            color: primaryColor,
          }}
        >
          GitHub
        </Button>
      </Grid>
    </Grid>
  );
};

export default Contact;
