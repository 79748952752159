import { Grid, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Grid textAlign={"center"}>
      <Typography mt={2} fontSize={"12px"}>
        <a
          target="_blank"
          href="https://github.com/abhijeetsatpute"
          rel="noreferrer"
        >
          Abhijeet
        </a>{" "}
        © 2024
      </Typography>
    </Grid>
  );
};

export default Footer;
